<template>
  <div>
    <ImpersonatorBanner
      v-if="impersonatorName"
      :impersonator-name="impersonatorName"
      class="sticky top-0 z-50"
    />
    <ReleaseBanner
      v-else-if="showReleaseBanner"
      class="sticky top-0 z-50"
    />
    <NavBar
      v-model:display-mobile-menu="displayMobileMenu"
      :banner-shown-above="!!(impersonatorName || showReleaseBanner)"
    />

    <USlideover
      v-model="displayMobileMenu"
      side="left"
      class="top-12"
    >
      <SidebarContent
        include-header
        @close="displayMobileMenu = false"
      />
    </USlideover>

    <main class="flex h-full min-h-screen flex-col justify-between bg-white sm:pt-12 print:pt-0">
      <slot />

      <div class="flex items-center justify-center bg-tertiary py-4 text-xs text-grey-blue">
        © 2024 TruRating Ltd
      </div>
    </main>

    <ReleaseModal
      v-if="canReadReleases"
      :model-value="(newReleaseAvailable && latestRelease?.releaseType === 'major')"
    />
  </div>
</template>

<script setup lang="ts">
// internal refs
const displayMobileMenu: Ref<boolean> = ref(false)

// store actions
const userStore = useUserStore()
const releaseStore = useReleaseStore()

const { currentUserPreferences } = storeToRefs(userStore)
const { latestRelease } = storeToRefs(releaseStore)

await releaseStore.fetchReleases()
await userStore.fetchCurrentUserPreferences()
await userStore.fetchCurrentUser() // fetches user for permissions
const impersonatorName = userStore.fetchImpersonatorName()

// computed
const newReleaseAvailable: ComputedRef<boolean> = computed(() => {
  if (!latestRelease.value || !currentUserPreferences.value) {
    return false
  }

  const lastAcknowledgedRelease = currentUserPreferences.value.find(p => p.name === 'LastAcknowledgedRelease')
  const lastIgnoredRelease = currentUserPreferences.value.find(p => p.name === 'LastIgnoredRelease')

  if (lastAcknowledgedRelease?.value !== latestRelease.value.releaseNumber
    && lastIgnoredRelease?.value !== latestRelease.value.releaseNumber) {
    return true
  }

  return false
})
const canReadReleases = computed(() => checkPermissions({ permissions: 'Releases.Read' }))
const showReleaseBanner = computed(() =>
  canReadReleases.value
  && newReleaseAvailable.value
  && latestRelease.value
  && latestRelease.value.releaseType === 'minor')

useHead({
  meta: [
    { name: 'robots', content: 'noindex, nofollow' }
  ]
})
</script>
