<template>
  <div>
    <div class="md:my-2">
      <div
        v-for="item in dashboards"
        :key="item.id"
        class="
          m-2 flex cursor-pointer items-center justify-between rounded-lg px-3 py-2 hover:bg-blue-7
          has-[.is-active-link]:bg-trublue
          has-[.is-active-link]:text-white
          has-[.is-active-link]:hover:!bg-blue-3
        "
      >
        <NuxtLink
          :to="item.type === 'comments' ? `/dashboard/comment/${item.id}` : `/dashboard/${item.id}`"
          active-class="is-active-link"
          class="peer"
        >
          {{ item.translateName ? $t('dashboards.name.' + item.name) : item.name }}
        </NuxtLink>
        <UIcon
          id="dashboard-favourite-icon"
          :name="item.isStarred ? 'i-mdi-star': 'i-mdi-star-outline'"
          class="!size-5 cursor-pointer"
          :class="{
            'text-star-orange': item.isStarred,
            'text-grey-blue peer-[.is-active-link]:text-light-grey': !item.isStarred,
          }"
          @click="setDashboardState(item.id, !item.isStarred, !!item.isDefault)"
        />
      </div>
    </div>
    <template v-if="totalRecentlyViewed > 5">
      <UDivider />
      <div class="m-2">
        <NuxtLink
          id="dashboard-library-button"
          exact-active-class="[&.is-active]:bg-trublue [&.is-active]:text-white [&.is-active]:hover:!bg-blue-3"
          class="inline-block w-full cursor-pointer rounded-lg px-3 py-2 text-trublue hover:bg-blue-7"
          :to="(rootOrgId ? `/library/${rootOrgId}`: '/library') + '?tab=recentlyViewed'"
          :class="{ 'is-active': route.fullPath.includes('?tab=recentlyViewed') }"
        >
          {{ $t('more') }} ({{ totalRecentlyViewed - 5 }})
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { DashboardLibraryItem } from '~/types'

const route = useRoute()

defineProps<{
  totalRecentlyViewed: number
  dashboards: Array<DashboardLibraryItem>
  rootOrgId: number | undefined
}>()
const emit = defineEmits<{ (e: 'update'): void }>()

async function setDashboardState(dashboardId: string, isStarred: boolean, isDefault: boolean) {
  await $hubFetch(`api/v4/dashboards/${dashboardId}/state`, {
    method: 'put',
    body: {
      isStarred,
      isDefault
    }
  })

  emit('update')
}
</script>
