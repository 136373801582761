<template>
  <div>
    <HubSelect
      v-if="organisationOptions.length > 1"
      id="nav-organisation-select-box"
      v-model="rootOrgId"
      class="m-2 mt-6 text-grey-blue"
      :options="organisationOptions"
      value-attribute="value"
    />

    <div
      v-if="loadingDashboards"
      class="m-4 mt-8 flex items-center justify-center gap-2 text-grey-blue"
    >
      <UIcon name="i-line-md-loading-twotone-loop" />
      <div>{{ $t('loadingData') }}</div>
    </div>

    <div
      v-else
      class="mt-4 text-sm text-grey-blue"
    >
      <div
        v-show="defaultDashboards.length"
        id="nav-default-dashboards"
      >
        <div class="flex flex-col gap-2">
          <NuxtLink
            v-for="dashboard in defaultDashboards"
            :key="dashboard.id"
            :to="dashboard.type === 'comments' ? `/dashboard/comment/${dashboard.id}` : `/dashboard/${dashboard.id}`"
            active-class="bg-trublue !text-white hover:!bg-blue-3 is-active-link"
            class="group mx-3 flex cursor-pointer items-center justify-between rounded-lg px-3 py-2 hover:bg-blue-7"
          >
            <div>{{ dashboard.translateName ? $t('dashboards.name.' + dashboard.name) : dashboard.name }}</div>
            <div
              class="flex items-center justify-start gap-1
              text-2xs font-medium text-dark-grey group-[.is-active-link]:text-light-grey"
            >
              <UIcon
                v-if="dashboard.isDefault"
                name="i-mdi-home-outline"
                class="!size-3.5"
              />
              {{ dashboard.isDefault ? $t('dashboards.nav.myDefault') : 'not-my-default-add-handling-for-groups' }}
            </div>
          </NuxtLink>
        </div>
        <UDivider class="my-2" />
      </div>
      <div
        v-show="recentlyUpdatedDashboards.length && (defaultDashboards.length < 2 && starredDashboards.length < 2)"
        id="nav-recently-updated-dashboards"
        :class="{ 'mt-4': defaultDashboards.length < 3 }"
      >
        <div class="mx-4 mb-4 text-xs font-medium text-dark-grey">
          {{ $t('dashboards.nav.recentlyUpdated') }}
        </div>
        <div class="flex flex-col gap-2">
          <NuxtLink
            v-for="dashboard in recentlyUpdatedDashboards"
            :key="dashboard.id"
            :to="dashboard.type === 'comments' ? `/dashboard/comment/${dashboard.id}` : `/dashboard/${dashboard.id}`"
            active-class="bg-trublue !text-white hover:!bg-blue-3"
            class="mx-3 cursor-pointer rounded-lg px-3 py-2 hover:bg-blue-7"
          >
            {{ dashboard.translateName ? $t('dashboards.name.' + dashboard.name) : dashboard.name }}
          </NuxtLink>
        </div>
        <UDivider class="my-2" />
      </div>

      <div
        v-show="starredDashboards.length"
        id="nav-favourite-dashboards"
      >
        <div class="mx-4 mb-4 text-xs font-medium text-dark-grey">
          {{ $t('dashboards.library.tabs.favourites') }}
        </div>
        <div class="flex flex-col gap-2">
          <NuxtLink
            v-for="dashboard in starredDashboards"
            :key="dashboard.id"
            :to="dashboard.type === 'comments' ? `/dashboard/comment/${dashboard.id}` : `/dashboard/${dashboard.id}`"
            active-class="bg-trublue text-white hover:!bg-blue-3"
            class="mx-3 cursor-pointer rounded-lg px-3 py-2 hover:bg-blue-7"
          >
            <div class="flex items-center justify-between">
              <div>{{ dashboard.translateName ? $t('dashboards.name.' + dashboard.name) : dashboard.name }}</div>
              <UIcon
                name="i-mdi-star"
                class="!size-5 text-star-orange"
              />
            </div>
          </NuxtLink>
        </div>
        <UDivider class="mt-2" />
      </div>

      <div
        v-show="recentlyViewedDashboards.length"
        id="recently-viewed"
        class="cursor-pointer rounded-lg  has-[.is-expanded]:bg-blue-7"
      >
        <UAccordion
          v-if="smallOrMediumViewport"
          :items="[{ label: $t('dashboards.library.tabs.recentlyViewed') }]"
          variant="ghost"
          :ui="{ item: { padding: '' } }"
        >
          <template #default="{ open }">
            <div
              class="mx-3 my-2 flex items-center justify-between rounded-lg px-3 py-2
              hover:bg-blue-7 has-[.is-expanded]:my-0 has-[.is-expanded]:py-4"
              :class="{ 'is-expanded': open }"
            >
              <div>{{ $t('dashboards.library.tabs.recentlyViewed') }}</div>
              <UIcon
                name="i-mdi-chevron-right"
                class="!size-5 transition-transform duration-200"
                :class="[open && 'rotate-90']"
              />
            </div>
          </template>
          <template #item>
            <DashboardNavRecentlyViewed
              :dashboards="recentlyViewedDashboards"
              :root-org-id="rootOrgId"
              :total-recently-viewed="recentlyViewedDashboardsTotalCount"
              @update="fetchAllDashboards"
            />
          </template>
        </UAccordion>
        <UPopover
          v-else
          :close-delay="250"
          :popper="{ resize: true, scroll: false }"
          :ui="{
            width: 'w-80',
            base: 'h-auto text-sm text-clip text-wrap',
            popper: { strategy: 'fixed', placement: 'left' },
          }"
        >
          <div class="mx-3 my-2 flex w-full items-center justify-between rounded-lg px-3 py-2">
            <div>{{ $t('dashboards.library.tabs.recentlyViewed') }}</div>
            <UIcon
              name="i-mdi-chevron-down"
              class="!size-5"
            />
          </div>

          <template #panel>
            <DashboardNavRecentlyViewed
              :dashboards="recentlyViewedDashboards"
              :root-org-id="rootOrgId"
              :total-recently-viewed="recentlyViewedDashboardsTotalCount"
              @update="fetchAllDashboards"
            />
          </template>
        </UPopover>
      </div>
      <UDivider class="mb-2" />
      <div class="mx-2">
        <NuxtLink
          id="dashboard-library-button"
          active-class="bg-trublue text-white hover:!bg-blue-3"
          class="inline-block w-full cursor-pointer rounded-lg px-3 py-2 hover:bg-blue-7"
          :to="`/library/${rootOrgId}`"
        >
          {{ $t('dashboards.library.title') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import DashboardNavRecentlyViewed from './DashboardNavRecentlyViewed.vue'
import type { DashboardLibraryItem } from '~/types'

const breakpoints = useBreakpoints(breakpointsTailwind)
const activeBreakpoints = breakpoints.active()

const organisationStore = useOrganisationStore()
const dashboardStore = useDashboardStore()

const { organisations, currentOrganisationId } = storeToRefs(organisationStore)

await organisationStore.fetchOrganisations()
const rootOrgId: Ref<undefined | number> = ref()
const loadingDashboards: Ref<boolean> = ref(true)

// temp
const recentlyUpdatedDashboards: Ref<Array<DashboardLibraryItem>> = ref([])
const starredDashboards: Ref<Array<DashboardLibraryItem>> = ref([])
const defaultDashboards: Ref<Array<DashboardLibraryItem>> = ref([])
const recentlyViewedDashboards: Ref<Array<DashboardLibraryItem>> = ref([])
const recentlyViewedDashboardsTotalCount: Ref<number> = ref(0)

const organisationOptions = computed(() => {
  return organisationStore.organisations.map(org => ({
    label: org.organisationNodeName,
    value: org.rootOrganisationNodeId
  }))
})
const smallOrMediumViewport = computed(() =>
  !activeBreakpoints.value || ['xs', 'sm', 'md'].includes(activeBreakpoints.value)
)

watch(
  () => organisations.value,
  () => {
    if (organisations.value.length) {
      if (currentOrganisationId.value) {
        rootOrgId.value = currentOrganisationId.value
        return
      }

      rootOrgId.value = organisations.value[0].rootOrganisationNodeId
    }
  },
  { immediate: true }
)

watch(
  () => rootOrgId.value,
  fetchAllDashboards,
  { immediate: true }
)

async function fetchAllDashboards() {
  loadingDashboards.value = true

  if (!rootOrgId.value) {
    loadingDashboards.value = false
    return
  }

  await Promise.all([
    getRecentlyViewed(),
    getMyDefaults(),
    getRecentlyUpdated(),
    getFavourites()
  ])

  loadingDashboards.value = false
}

async function getFavourites() {
  starredDashboards.value = []

  const dashboards = await dashboardStore.fetchDashboardsForOrganisation(
    rootOrgId.value as number,
    true,
    false,
    { $top: 5, $filter: 'isStarred eq true', $orderby: 'lastAccessedDate desc' }
  )

  starredDashboards.value = dashboards || []
}

async function getRecentlyViewed() {
  console.log('getRecentlyViewed')
  recentlyViewedDashboards.value = []
  recentlyViewedDashboardsTotalCount.value = 0

  const dashboards = (await $hubFetch(`/api/v4/organisations/${rootOrgId.value}/dashboards`, { query: {
    $top: 5,
    $filter: 'lastAccessedDate ne null',
    $orderby: 'lastAccessedDate desc'
  } })) as { count: number, items: Array<DashboardLibraryItem> }

  recentlyViewedDashboards.value = dashboards.items || []
  recentlyViewedDashboardsTotalCount.value = dashboards.count || 0
}
async function getRecentlyUpdated() {
  recentlyUpdatedDashboards.value = []

  const dashboards = await dashboardStore.fetchDashboardsForOrganisation(
    rootOrgId.value as number,
    true,
    false,
    { $top: 5, $orderby: 'updatedDate desc' }
  )

  recentlyUpdatedDashboards.value = dashboards || []
}
async function getMyDefaults() {
  defaultDashboards.value = []

  const dashboards = await dashboardStore.fetchDashboardsForOrganisation(
    rootOrgId.value as number,
    true,
    false,
    {
      $top: 5,
      $filter: 'isDefault eq true',
      $orderby: 'lastAccessedDate desc'
    })

  defaultDashboards.value = dashboards || []
}
</script>
